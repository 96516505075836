

























































































































import Vue from 'vue';
import { formatDateTime } from '@/utils/tools';
import SessionCardButtons from '@/components/Presenter/SessionCardButtons.vue';

export default Vue.extend({
  props: {
    clickHandler: Function,
    session: Object,
    isPausePlayVisible: Boolean,
  },
  components: { SessionCardButtons },
  data() {
    return {
      isCollapsed: false,
      showSessionOptionsMenu: false,
    };
  },
  computed: {
    computedLabelColor(): string {
      const { session } = this;
      const isRunningSession =
        session &&
        session.startAt &&
        session.startAt.seconds &&
        session.endAt &&
        session.endAt.seconds &&
        session.startAt.toDate() < Date.now() &&
        session.endAt.toDate() > Date.now();
      const isPastSession =
        session &&
        session.endAt &&
        session.endAt.seconds &&
        session.endAt.toDate() < Date.now();

      if (session && session.isPaused) {
        return 'bg-yellow-500';
      } else {
        if (session.sessionUnlimited || isRunningSession) {
          return 'bg-secondary';
        } else {
          if (isPastSession) {
            return 'bg-light-gray';
          } else {
            return 'bg-primary';
          }
        }
      }
    },

    wordCount(): string {
      return this.session.description.split(' ').splice(0, 20).join(' ');
    },

    computedShareLink(): string {
      if (this.session && this.session.shareToken) {
        const shareURL =
          'https://sterntv.reaction.link/j/' + this.session.shareToken;
        return shareURL;
      }
      return '';
    },
  },
  methods: {
    formatDateTime(input: any): string {
      return formatDateTime(input);
    },

    showCopySuccessToast(): void {
      Vue.$toast.open({
        message: this.$t('views.presenter.session.statsSuccessToast') as string,
        type: 'success',
        position: 'top-right',
      });
    },

    showCopyFailureToast(): void {
      Vue.$toast.open({
        message: this.$t('views.presenter.session.statsFailureToast') as string,
        type: 'error',
        position: 'top-right',
      });
    },

    onClick() {
      this.clickHandler(this.session);
    },

    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },

    closeSessionOptionsMenu() {
      this.showSessionOptionsMenu = false;
    },

    toggleSessionOptionsMenu() {
      this.showSessionOptionsMenu = !this.showSessionOptionsMenu;
    },

    duplicateSession() {
      if (this.session.id) {
        this.$router.push({
          name: 'Duplicate Session',
          params: { presenterSessionId: this.session.shareToken },
        });
      }
    },

    editSession() {
      if (this.session.id) {
        this.$router.push({
          name: 'Edit Session From Card',
          params: { presenterSessionId: this.session.shareToken },
        });
      }
    },

    deleteSession() {
      if (this.session.id) {
        this.showSessionOptionsMenu = false;
        if (
          confirm(
            this.$t('components.presenter.sessionCardDeleteMsg') as string
          )
        ) {
          this.$store.dispatch('presenter/removeSession', {
            sessionId: this.session.id,
            sessionPath: this.$route.path,
          });
        }
      }
    },

    pauseSession() {
      if (this.session) {
        this.showSessionOptionsMenu = false;
        if (this.session.isPaused) {
          if (
            confirm(
              this.$t(
                'components.presenter.sessionCardContinueSession'
              ) as string
            )
          ) {
            this.$store.dispatch('presenter/pauseSession', {
              sessionId: this.session.id,
              state: false,
            });
          }
        } else {
          if (
            confirm(
              this.$t('components.presenter.sessionCardPauseSession') as string
            )
          ) {
            this.$store.dispatch('presenter/pauseSession', {
              sessionId: this.session.id,
              state: true,
            });
          }
        }
      }
    },
  },
});
